<template>
  <section class="contact-container row-10">

    <div class="col-1 md:col-2 lg:col-3 bg-green" />

    <div class="contact-left inset col-9 md:col-3 lg:col-2 bg-green text-salmon">
      <div class="contact-content">
        <h2 class="contact-title">
          {{ payload.title }}
        </h2>
        <div class="contact-text" v-html="payload.text" />
      </div>
    </div>

    <div class="contact-right md:col-5 hidden md:block">
      <Image :src="payload.einzelbild" />
    </div>

  </section>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.contact-right {
  position: relative;
  img {
    padding-left: 1rem;
    position: absolute;
    height: 100%;
    object-fit: contain;
    object-position: left;
  }
}
</style>
